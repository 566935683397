export const MENU_ITEMS = [
  {
    label: "Solutions",
    path: "/solutions",
    offset: "70",
  },
  {
    label: "Services",
    path: "/services",
    offset: "70",
  },
  // {
  //   label: "Partnerships",
  //   path: "#",
  //   offset: "70",
  // },
  // {
  //   label: "Expertise",
  //   path: "#",
  //   offset: "70",
  // },
  // {
  //   label: "Thought Leadership",
  //   path: "#",
  //   offset: "70",
  // },
];

import banner1 from "../../../common/assets/image/webApp/banner-1.svg";
import bannerServices from "../../../common/assets/image/webApp/banner-services.svg";
import bannerSolutions from "../../../common/assets/image/webApp/banner-solutions.svg";
import bannerIndustries from "../../../common/assets/image/webApp/banner-industries.png";
import bannerAbout from "../../../common/assets/image/webApp/banner-contact.svg";
import bannerContact from "../../../common/assets/image/webApp/banner-contact.svg";
import bannerPartnerships from "../../../common/assets/image/webApp/banner-partnerships.svg";

import product1 from "../../../common/assets/image/webApp/product1.svg";
import product2 from "../../../common/assets/image/webApp/product2.svg";
import product3 from "../../../common/assets/image/webApp/product3.svg";
import introServices1 from "../../../common/assets/image/webApp/services-1.svg";
import introServices2 from "../../../common/assets/image/webApp/services-2.svg";
import introServices3 from "../../../common/assets/image/webApp/services-3.svg";
import introServices4 from "../../../common/assets/image/webApp/services-4.svg";
import introServicesSection1 from "../../../common/assets/image/webApp/services-section-1.png";
import intro1 from "../../../common/assets/image/webApp/intro-1.png";
import blog1 from "../../../common/assets/image/webApp/blog-1.jpg";
import blog2 from "../../../common/assets/image/webApp/blog-2.jpg";
import blog3 from "../../../common/assets/image/webApp/blog-3.jpg";
import company1 from "../../../common/assets/image/webApp/brand-1-1.svg";
import company2 from "../../../common/assets/image/webApp/brand-1-2.svg";
import company3 from "../../../common/assets/image/webApp/brand-1-3.svg";
import company4 from "../../../common/assets/image/webApp/brand-1-4.png";
import company5 from "../../../common/assets/image/webApp/brand-1-5.png";
import company6 from "../../../common/assets/image/webApp/brand-1-6.png";
import bubbleImage1 from "../../../common/assets/image/webApp/call-bubble-1.svg";
import bubbleImage2 from "../../../common/assets/image/webApp/call-bubble-2.svg";
import bubbleImage3 from "../../../common/assets/image/webApp/call-bubble-3.svg";
import bubbleImage4 from "../../../common/assets/image/webApp/call-bubble-4.svg";
import bubbleImage5 from "../../../common/assets/image/webApp/call-bubble-5.svg";
import bubbleImage6 from "../../../common/assets/image/webApp/call-bubble-6.svg";
import bubbleImage7 from "../../../common/assets/image/webApp/call-bubble-7.svg";
import customerSupportImageShape from "../../../common/assets/image/webApp/customer-support-shape.svg";
import customerSupportImage from "../../../common/assets/image/webApp/customer-support.png";
import feature1 from "../../../common/assets/image/webApp/feature-1.png";
import feature2 from "../../../common/assets/image/webApp/feature-2.png";
import feature3 from "../../../common/assets/image/webApp/feature-3.png";
import feature4 from "../../../common/assets/image/webApp/feature-4.png";
import feature5 from "../../../common/assets/image/webApp/feature-5.png";
import footerDribble from "../../../common/assets/image/webApp/footer-dribble.svg";
import footerFacebook from "../../../common/assets/image/webApp/footer-facebook.svg";
import footerTwitter from "../../../common/assets/image/webApp/footer-twitter.svg";
import footerLogo from "../../../common/assets/image/webApp/logo-xrd-dark.svg";
import secure2 from "../../../common/assets/image/webApp/secure-1.png";
import secure1 from "../../../common/assets/image/webApp/secure-2.png";
import secureImage from "../../../common/assets/image/webApp/secure-dashboard.png";
import service1 from "../../../common/assets/image/webApp/service-1.png";
import service2 from "../../../common/assets/image/webApp/service-2.png";
import service3 from "../../../common/assets/image/webApp/service-3.png";
import service4 from "../../../common/assets/image/webApp/service-4.png";
import testimonial1 from "../../../common/assets/image/webApp/testimonials-1-1.png";
import testimonial2 from "../../../common/assets/image/webApp/testimonials-1-2.png";
import testimonial3 from "../../../common/assets/image/webApp/testimonials-1-3.png";
import testimonialBlockImage from "../../../common/assets/image/webApp/testimonials-block-image.png";
export const BANNER_DATA = {
  title: "x-RD are Australia’s Complex Technology Problem Solvers",
  text: "x-RD is an Australian technology company focused on cyber security, automation and AI for secure environments and high compliance sectors.",
  tagline: "*No Credit card required",
  button: {
    label: "Learn More",
    link: "/solutions",
  },
  image: [
    {
      src: banner1,
    },
  ],
};

export const BANNER_SOLUTIONS_DATA = {
  title: "Solutions",
  text: "We provide leading-edge products specialising in automation & experimentation, cyber security and artificial intelligence & machine learning.",
  tagline: "*No Credit card required",
  button: {
    label: "Explore",
    link: "#section-secd3v",
  },
  image: [
    {
      src: bannerSolutions,
    },
  ],
};

export const BANNER_GOVERNMENT_DATA = {
  title: "Government",
  text: "We provide leading automation & experimentation engineering.",
  tagline: "*No Credit card required",
  button: {
    label: "More information",
    link: "https://www.secd3v.com.au",
  },
  image: [
    {
      src: bannerSolutions,
    },
  ],
};

export const BANNER_SERVICES_DATA = {
  title: "Services",
  text: "Specialising in artificial intelligence, software development & automation, cyber security and applied research, we thrive when challenged with complex and difficult problems.",
  tagline: "*No Credit card required",
  button: {
    label: "Explore",
    link: "#software-development",
  },

  image: [
    {
      src: bannerServices,
    },
  ],
};

export const BANNER_CASE_STUDIES_DATA = {
  title: "Case Studies",
  text: "This is section to proved a short description of the services page.",
  tagline: "*No Credit card required",
  button: {
    label: "Placeholder",
    link: "https://www.secd3v.com.au",
  },

  image: [
    {
      src: bannerServices,
    },
  ],
};

export const BANNER_INDUSTRIES_DATA = {
  title: "Industries",
  text: "x-RD works across multiple industries and has highly accredited certifications.",
  tagline: "*No Credit card required",
  button: {
    label: "More Information",
    link: "#disp-membership",
  },

  image: [
    {
      src: bannerIndustries,
    },
  ],
};

export const BANNER_SECURITY_COMPLIANCE_DATA = {
  title: "Security Compliance",
  text: "Processes for continuous monitoring and evaluation of systems.",
  tagline: "*No Credit card required",
  button: {
    label: "More information",
    link: "/contact",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_ABOUT_DATA = {
  title: "About",
  text: "At x-RD, our passion is providing novel and responsible solutions.",
  tagline: "*No Credit card required",
  button: {
    label: "Contact",
    link: "/contact",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_CAREERS_DATA = {
  title: "Careers",
  text: "Open positions at x-RD",
  tagline: "*No Credit card required",
  button: {
    label: "List of Available Positions",
    link: "#ListCareers",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_PHILANTROPIC_DATA = {
  title: "Philantropic Initiatives",
  text: "This is section to proved a short description of the about page.",
  tagline: "*No Credit card required",
  button: {
    label: "Contact Details",
    link: "/contact",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_PARTNERSHIPS_DATA = {
  title: "Partnerships",
  text: "x-RD have cultivated strong, dedicated partnerships that support all aspects of our organisation.",
  tagline: "*No Credit card required",
  button: {
    label: "Explore",
    link: "/partnerships",
  },

  image: [
    {
      src: bannerPartnerships,
    },
  ],
};

export const BANNER_BLOG_DATA = {
  title: "Blog",
  text: "This is the blog section.",
  tagline: "*No Credit card required",
  button: {
    label: "List of Partnerships",
    link: "/partnerships",
  },

  image: [
    {
      src: bannerAbout,
    },
  ],
};

export const BANNER_CONTACT_DATA = {
  title: "Contact",
  text: "Get in touch with x-RD to learn more about our solutions, services and how we are making a difference through engineering, science and technology.",
  tagline: "*No Credit card required",
  button: {
    label: "Contact Details",
    link: "/contact",
  },

  image: [
    {
      src: bannerContact,
    },
  ],
};

export const FEATURE_DATA = {
  blockTitle: {
    title: "Choose dashboard for every stage of your customer journey",
    text: "The rise of mobile devices transforms the way we consume information entirely and the world's most relevant channels such as Facebook and Instagram are almost exclusively used on mobile",
  },
  post: [
    {
      icon: feature1,
      text: "Fast Performance",
    },
    {
      icon: feature2,
      text: "User Customization",
    },
    {
      icon: feature3,
      text: "Modify structure",
    },
    {
      icon: feature4,
      text: "Customer Analysis",
    },
    {
      icon: feature5,
      text: "Instant Support",
    },
  ],
};

export const VIDEO_DATA = {
  title: "Meet Amelia, Lead Software Engineer",
  videoID: "hW98BFnVCm8",
};

export const SERVICE_DATA = [
  {
    icon: service1,
    title: "Analysis & Development",
    text: "We enjoy office retreats and visit famous places of our country and abroad also! We have planned to extend our business.",
  },
  {
    icon: service2,
    title: "User experience & Performance",
    text: "We enjoy office retreats and visit famous places of our country and abroad also! We have planned to extend our business.",
  },
  {
    icon: service3,
    title: "Clean & Editable Environment",
    text: "We enjoy office retreats and visit famous places of our country and abroad also! We have planned to extend our business.",
  },
  {
    icon: service4,
    title: "Optimized & User Friendly",
    text: "We enjoy office retreats and visit famous places of our country and abroad also! We have planned to extend our business.",
  },
];

export const COMPANY_DATA = {
  title: "Key Partners",
  images: [
    {
      src: company1,
      url: "https://aws.amazon.com/",
    },
    {
      src: company2,
      url: "https://about.gitlab.com/",
    },
    {
      src: company3,
      url: "https://www.salesforce.com/",
    },
  ],
};

export const EXPERTISE_DATA = {
  sectionImage: secureImage,
  blockTitle: {
    title: "Our Expertise",
    title2: "",
  },
  button: {
    link: "#",
    label: "Learn more",
  },
  posts: [
    {
      icon: secure1,
      text: "We focus on ‘doing’ at speed, and believe that solving difficult and uncertain problems with technology and AI can only be achieved through multi-disciplined expert experimentation, engineering and execution. The human aspect and other societal factors are equally important in any solution, and we aim to achieve this holistic approach through experimentation and",
    },
    {
      icon: secure2,
      text: "partnerships with leaders, innovators and game-changers in these spaces. Using technology and our experience to make a real difference is important to x-RD and our staff, and we are proud of our work in developing digital solutions for philanthropic causes and societal needs.",
    },
  ],
};

export const SECURE_DASHBOARD_DATA = {
  sectionImage: secureImage,
  blockTitle: {
    title: "Most Promising & Secure Dashboard with tracking user referral code",
    text: "Pick one of our stock themes, or create your custom theme with the most advanced theme editor on any online survey building tool.",
  },
  posts: [
    {
      icon: secure1,
      title: "Fast & Instant Transfer",
      text: "We’re driven beyond just finishing the projects. We want to find solutions.",
    },
    {
      icon: secure2,
      title: "File Management System",
      text: "We’re driven beyond just finishing the projects. We want to find solutions.",
    },
  ],
};

export const INTRO_DATA = {
  blockTitle: {
    title: "Who We Are",
    text: "x-RD stands for eXperimental Research and Development. Our company was founded in Canberra in 2019 by technologists, cyber security specialists, AI scientists, researchers and analysts from government, industry, academia and private sectors. The company has been driven to innovate through creativity, high quality engineering and making a difference.",
    button: {
      link: "/about",
      label: "About Us",
    },
  },
  image: intro1,
};

export const SECTION_SERVICES = {
  sections: [
    {
      title: "Software Development",
      text: "Software Development at x-RD focuses on agile approaches, strong security and engineering processes, and end-to-end software automation. These enable us to solve and accelerate technology outcomes particularly with complex problems involving technologies like Artificial Intelligence, and ensure our software is secure, performant and reliable.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices1,
    },
    {
      title: "Cloud-based Development",
      text: "Alongside our Software Development, our Cloud Engineering and automation experience is focused on application, back-end, cloud and edge development across a vast range of technologies and platforms.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices2,
    },
    {
      title: "AI/ML",
      text: "Artificial Intelligence at x-RD focuses on applying and operationalising AI against complex problems in real world conditions, including engineering and experimentation. Our solutions are further designed to meet automation, decision augmentation, insight and efficiency requirements, whilst considering interoperability, responsible AI, and privacy. Co-development and the experiential component of Artificial Intelligence & operations is fundamental to improving the understanding and adoption of AI technologies.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices3,
    },
    {
      title: "Cyber Security",
      text: "Cyber Security at x-RD believes that understanding the interactions of users and organisations with IT systems is a key aspect to achieving full-spectrum cyber security. We actively invest in research and development to identify new approaches, technologies, processes and frameworks to improve software, IT and the wider security ecosystem. Currently, this includes an initiative to secure cloud-to-the-edge and beyond (IoT). At x-RD we believe it is important to create a safe, trustworthy and secure environment for businesses and society as a whole, and a positive culture of security to be the norm.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices4,
    },
  ],
};

export const SECTION_CASE_STUDIES = {
  sections: [
    {
      title: "Case Study 1",
      text: "Body placeholder for text paragraph. A paragraph is a self-contained unit of text dealing with a particular point or idea.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices1,
    },
    {
      title: "Case Study 2",
      text: "Needs content.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices2,
    },
    {
      title: "Case Study 3",
      text: "Needs content.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices3,
    },
    {
      title: "Case Study 4",
      text: "Needs content.",
      button: {
        link: "/about",
        label: "Learn More",
      },
      image: introServices4,
    },
  ],
};

export const SECTION_SOLUTIONS = {
  sections: [
    {
      title: "secd3v",
      text: "secd3v is an IRAP assessed and separate tenancy DevSecOps GitLab software factory as–a-service that can be integrated into your corporate and enterprise networks.",
      button: {
        link: "https://www.secd3v.com.au/",
        label: "Learn More",
      },
      image: introServicesSection1,
    },
    {
      title: "s4nctum",
      text: "s4nctum is a SaaS-based AI security platform that simplifies cyber security for data scientists, ML engineers and developers through automated AI vulnerability analysis, management and remediation.",
      button: {
        link: "/contact",
        label: "Coming soon",
      },
      image: introServicesSection1,
    },
    {
      title: "hab1tat",
      text: "hab1tat is an on demand fully automated and secure cloud environment as-a-service for a range of software, AI and business requirements. Hab1tat is an IRAP assessed on demand IT and secure cloud environment-as-a-service with custom applications that is self contained or can be integrated into your corporate and enterprise network.",
      button: {
        link: "/contact",
        label: "Enquire for more information",
      },
      image: introServicesSection1,
    },
  ],
};

export const SECTION_GOVERNMENT = {
  sections: [
    {
      title: "Government Industry 1",
      text: "Body placeholder for text paragraph. A paragraph is a self-contained unit of text dealing with a particular point or idea.",
      button: {
        link: "https://www.secd3v.com.au/",
        label: "Go to SecD3v",
      },
      image: introServicesSection1,
    },
    {
      title: "Government Industry 2",
      text: "x-RD are a Defence Industry Security Program partner, and can assist companies in achieving their DISP membership. x-RD provides genuine security advice and hands-on support to achieve your DISP membership. With our cyber security expertise and depth, we have an advantage over other service providers.",
      button: {
        link: "https://www.secd3v.com.au/",
        label: "Go to SecD3v",
      },
      image: introServicesSection1,
    },
    {
      title: "Case Study 1",
      text: "Body placeholder for text paragraph. A paragraph is a self-contained unit of text dealing with a particular point or idea.",
      button: {
        link: "#",
        label: "Coming soon",
      },
      image: introServicesSection1,
    },
  ],
};

export const SECTION_INDUSTRIES = {
  sections: [
    {
      title: "DISP Membership",
      text: "x-RD are a Defence Industry Security Program partner, and can assist companies in achieving their DISP membership. x-RD provides genuine security advice and hands-on support to achieve your DISP membership. With our cyber security expertise and depth, we have an advantage over other service providers.",
      button: {
        link: "/about",
        label: "Go to SecD3v",
      },
      image: introServicesSection1,
    },
    {
      title: "SecD3v DevSecOps",
      text: "SecD3v DevSecOps is an IRAP certified and multi-tenanted DevSecOps GitLab software factory as–a-service that can be integrated into your corporate and enterprise networks.",
      button: {
        link: "/about",
        label: "Go to SecD3v",
      },
      image: introServicesSection1,
    },
  ],
};

export const SECTION_CERTS_DATA = [
  {
    icon: "",
    title: "ISO27001",
    text: "ISO/IEC 27001 is the international standard for information security. It sets out the specification for an effective ISMS (information security management system). ISO 27001's best-practice approach helps organisations manage their information security by addressing people, processes and technology.",
  },
  {
    icon: "",
    title: "SOC2",
    text: "SOC 2 is an auditing procedure that ensures your service providers securely manage your data to protect the interests of your organisation and the privacy of its clients.",
  },
  {
    icon: "",
    title: "HIPAA",
    text: "HIPAA laws are a series of federal regulatory standards that outline the lawful use and disclosure of protected health information in the United States.",
  },
  {
    icon: "",
    title: "CDR",
    text: "The definition of CDR data is broad. It includes data that has been ‘wholly or partly derived’ from data set out in the designation instrument, and data derived from any previously derived data.",
  },
];

export const SECTION_PANELS_MEMBER_DATA = [
  {
    icon: "",
    title: "ICTPA",
    text: "x-RD are part of the Commonwealth’s Defence ICT Provider Arrangement (ICTPA), one of the federal government’s largest IT panels.",
  },
  {
    icon: "",
    title: "CIOG",
    text: "x-RD are a panel member on Defence’s Chief Information Officer Group. The Group is responsible for ensuring Defence has a dependable, secure and integrated Defence Single Information Environment to support Defence business and military operations.",
  },
  {
    icon: "",
    title: "DTA BuyICT",
    text: "x-RD are listed on DTA’s BuyICT, which supports government to source ICT products and services, find sellers and discover existing government platforms.",
  },
  {
    icon: "",
    title: "DSTG",
    text: "x-RD are part of the Defence Science and Technology Group, which brings together interdisciplinary expertise from across Australia and around the world to address Defence and national security challenges.",
  },
];

export const SECTION_ABOUT = {
  sections: {
    title:
      "Providing novel & responsible solutions to complex problems that make a difference within society",
    text1:
      "At x-RD, our passion is providing novel and responsible solutions to complex problems that make a difference. Using our skills, diverse experience, and innovation, we help our customers and wider society better understand and solve complex problems through experimentation, advanced technologies, science, art, passion and great people. Our company was founded in Canberra in 2019 by multi-faceted technology specialists,",
    text2:
      "researchers and analysts, from industry, government, and defence sectors who had a passion for creativity, high quality engineering and making a difference. We have extended experience and knowledge across Government, Defence, Mining, Healthcare and Technology sectors, and also enjoy the challenge of working in different industries.",
    improving_technologies:
      "We have experience connecting groups in very remote communities, and working inclusively to tailor technology and education approaches to address the barriers faced by Aboriginal and Torres Strait Islander peoples. We are driven to ensure culturally appropriate opportunities, technologies and solutions are available through initiatives to Aboriginal, Torres Strait Islander peoples and other groups.",
    improving_diversity:
      "We are committed to making our workplace and IT practices more inclusive of diversity, in particular in the area of gender diversity. At an industry level, x-RD are actively involved in initiatives such as the Inclusive Naming Initiative (INI) to improve inclusive language in existing software development approaches. We are also investigating new technology roles and/or conditions to improve gender diversity within the technology industry, as well as other ways of achieving positive and inclusive outcomes.",
    improving_support:
      "We actively support veterans of the Australian Defence Force through employment opportunities and active research in areas relevant to the Department of Veteran’s Affairs, Veteran’s charities and Veteran’s support organisations. We are a proud, enthusiastic and supportive employer of Australian Defence Force Reservists, and are always willing to assist veterans looking to transition into technology careers in the Australia Public Service or industry through mentoring, advice, job experience or employment opportunities.",
    button: {
      link: "/about",
      label: "Learn More",
    },
    image: introServices1,
  },
};

export const SECTION_CONTACT = {
  sections: {
    contact_us: "Contact Us",
    contact_us_text:
      "Body placeholder for text paragraph, a paragraph is a self-contained unit of text description.",
    phone: "Phone",
    phone_content_act: "ACT - 02 6109 7672",
    phone_content_vic: "VIC - 03 8352 1976",
    address: "Address",
    address_content_act:
      "ACT - Suite 2.02, Level 2/15 London Cct, Canberra ACT 2601",
    address_content_vic:
      "VIC - Ground Floor 430 William St, West Melbourne VIC 3004",
    button: {
      link: "/about",
      label: "Email us",
    },
    image: introServices1,
  },
};

export const COUNTER_DATA = {
  blockTitle: {
    title: "Defining Innovative & Responsible Technology Experiences",
    text: "Specialising in artificial intelligence, software development & automation, cyber security and applied research, we thrive when challenged with complex and difficult problems. We are motivated to make positive change through our expertise, engineering, products and services.",
    button: {
      link: "#",
      label: "Learn More",
    },
  },
  posts: [
    {
      count: "80",
      title: "Up to",
      text: "Customer Response",
      symbol: "%",
    },
    {
      count: "99",
      title: "Consistent",
      text: "Performance Score",
      symbol: "%",
    },
    {
      count: "3.5",
      title: "Down to",
      text: "Response Time",
      symbol: "S",
    },
    {
      count: "5x",
      title: "Up to",
      text: "Faster then others",
      symbol: "",
    },
  ],
};

export const TESTIMONIALS_DATA = {
  blockImage: testimonialBlockImage,
  title: "What people say about our product",
  posts: [
    {
      image: testimonial1,
      text: "OMG! I cannot believe that I have got a brand new landing page after getting this template we are able to use our most used e-commerce template with modern and trending design.",
      name: "Mariana Dickey",
      designation: "UI Designer",
    },
    {
      image: testimonial2,
      text: "OMG! I cannot believe that I have got a brand new landing page after getting this template we are able to use our most used e-commerce template with modern and trending design.",
      name: "Jonathan Taylor",
      designation: "CEO at Creativex",
    },
    {
      image: testimonial3,
      text: "OMG! I cannot believe that I have got a brand new landing page after getting this template we are able to use our most used e-commerce template with modern and trending design.",
      name: "Krish Kendall",
      designation: "Creative Director",
    },
  ],
};

export const ABOUT_BOARD_DATA = {
  blockImage: testimonialBlockImage,
  title1: "Board",
  posts1: [
    {
      image: testimonial1,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit justo nibh, at iaculis nibh placerat interdum.",
      name: "Grant Worner",
      designation: "CEO",
    },
    {
      image: testimonial2,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit justo nibh, at iaculis nibh placerat interdum.",
      name: "Daniel Riedel",
      designation: "CPO",
    },
    {
      image: testimonial3,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit justo nibh, at iaculis nibh placerat interdum.",
      name: "Paul Smooker",
      designation: "COO",
    },
    {
      image: testimonial3,
      text: "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Suspendisse hendrerit justo nibh, at iaculis nibh placerat interdum.",
      name: "Campbell Blackburn",
      designation: "CTO",
    },
  ],
};

export const ABOUT_TEAM_DATA = {
  blockImage: testimonialBlockImage,
  title: "Management Team",
  posts: [
    {
      image: testimonial1,
      text: "Experienced leader in start-up businesses, international private equity firms and listed multinational companies.",
      name: "Grant Worner",
      designation: "CEO",
    },
    {
      image: testimonial2,
      text: "Experienced leader in start-up businesses, international private equity firms and listed multinational companies.",
      name: "Daniel Riedel",
      designation: "CPO",
    },
    {
      image: testimonial3,
      text: "Passionate about delivering advanced and modern technologies to customers to solve novel and challenging problems.",
      name: "Campbell Blackburn",
      designation: "CTO",
    },
  ],
};

export const TEAM_DATA = [
  {
    product: 0,
    title: "Grant Worner",
    price: "CEO",
    tagline: "with restrictions",
    planLabel: "Features:",
    options: [
      {
        text: "Experienced in start-up businesses, international private equity firms and listed multinational companies.",
      },
    ],
    button: {
      label: "LinkedIn",
      link: "https://www.linkedin.com/in/grant-worner-22b10b5/",
    },
  },
  {
    product: 0,
    title: "Daniel Riedel PhD",
    price: "CPO",
    tagline: "with restrictions",
    planLabel: "Features:",
    options: [
      {
        text: "A leader in developing, innovating and operationalising technology and machine learning.",
      },
    ],
    button: {
      label: "LinkedIn",
      link: "https://www.linkedin.com/in/daniel-riedel-phd-197a68bb/",
    },
  },
  {
    product: 0,
    title: "Campbell Blackburn",
    price: "CTO",
    tagline: "with restrictions",
    planLabel: "Features:",
    options: [
      {
        text: "Passionate about delivering modern technologies to customers to solve novel and challenging problems.",
      },
    ],
    button: {
      label: "LinkedIn",
      link: "https://www.linkedin.com/in/campbell-blackburn-132a351b6/",
    },
  },
];

export const PRODUCTS_DATA = [
  {
    product: 0,
    title: "SecD3v",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    image: product1,
    options: [
      {
        text: "Easy to consume DevSecOps-as-a-service",
      },
      {
        text: "IRAP assessed up to PROTECTED",
      },
      {
        text: "Supports multiple tenancies for differing requirements",
      },
      {
        text: "Provides access to secure software templates",
      },
    ],
    button: {
      label: "Learn More",
      link: "/solutions#secd3vdevops",
    },
  },
  {
    product: 0,
    title: "Hab1tat",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    image: product2,
    options: [
      {
        text: "Quick and easy to deploy IT network delivered as-a-service",
      },
      {
        text: "Focuses on achieving business value",
      },
      {
        text: "Offers software applications to high compliance sectors",
      },
      {
        text: "Simplified approach to purchasing and procurement",
      },
    ],
    button: {
      label: "Learn More",
      link: "/solutions#s4nctum",
    },
  },
  {
    product: 0,
    title: "Services",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    image: product3,
    options: [
      {
        text: "Software Development",
      },
      {
        text: "Cloud Engineering",
      },
      {
        text: "Applied AI & ML",
      },
      {
        text: "Cyber Security",
      },
    ],
    button: {
      label: "Learn More",
      link: "/solutions#s4nctum",
    },
  },
];

export const SERVICES_DATA = [
  {
    product: 0,
    title: "Software Development",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    image: introServices1,
    options: [
      {
        text: "Easy to consume DevSecOps-as-a-service",
      },
      {
        text: "IRAP assessed up to PROTECTED",
      },
      {
        text: "Supports multiple tenancies for differing requirements",
      },
      {
        text: "Provides access to secure software templates",
      },
    ],
    button: {
      label: "Learn More",
      link: "/solutions#secd3vdevops",
    },
  },
  {
    product: 0,
    title: "Cloud Computing",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    image: introServices2,
    options: [
      {
        text: "Quick and easy to deploy IT network delivered as-a-service",
      },
      {
        text: "Focuses on achieving business value",
      },
      {
        text: "Offers software applications to high compliance sectors",
      },
      {
        text: "Simplified approach to purchasing and procurement",
      },
    ],
    button: {
      label: "Learn More",
      link: "/solutions#hab1tat",
    },
  },
  {
    product: 0,
    title: "AI/ML",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    image: introServices3,
    options: [
      {
        text: "Software Development",
      },
      {
        text: "Cloud Engineering",
      },
      {
        text: "Applied AI & ML",
      },
      {
        text: "Cyber Security",
      },
    ],
    button: {
      label: "Learn More",
      link: "/services",
    },
  },
  {
    product: 0,
    title: "Applied Research",
    price: "Free Trial",
    tagline: "with restrictions",
    planLabel: "Features:",
    image: introServices4,
    options: [
      {
        text: "Software Development",
      },
      {
        text: "Cloud Engineering",
      },
      {
        text: "Applied AI & ML",
      },
      {
        text: "Cyber Security",
      },
    ],
    button: {
      label: "Learn More",
      link: "/services",
    },
  },
];

export const MONTHLY_PRICING_DATA = [
  {
    recommended: false,
    title: "Lite",
    price: "Free",
    tagline: "with restrictions",
    planLabel: "Plan includes:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Chat promt supported",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Start 14 days of free trial",
      link: "#",
    },
  },
  {
    recommended: true,
    title: "Pro",
    price: "$15.93",
    tagline: "Monthly",
    planLabel: "Plan includes:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Chat promt supported",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Start 14 days of free trial",
      link: "#",
    },
  },
];

export const YEARLY_PRICING_DATA = [
  {
    recommended: false,
    title: "Lite",
    price: "Free",
    tagline: "with restrictions",
    planLabel: "Plan includes:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Chat promt supported",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Start 14 days of free trial",
      link: "#",
    },
  },
  {
    recommended: true,
    title: "Pro",
    price: "$99.93",
    tagline: "Yearly",
    planLabel: "Plan includes:",
    options: [
      {
        text: "Manage conversations directly from your websites optimization.",
      },
      {
        text: "Unlimited links",
      },
      {
        text: "Chat promt supported",
      },
      {
        text: "Optimzed hashtags",
      },
      {
        text: "Own analytics platform",
      },
    ],
    button: {
      label: "Start 14 days of free trial",
      link: "#",
    },
  },
];

export const CUSTOMER_SUPPORT_DATA = {
  image: customerSupportImage,
  shapeImage: customerSupportImageShape,
  title: "Meet our Superheros who works dedicatedly with customer support",
  options: [
    {
      label: "Gift-giving social network – emotional vs transactional",
    },
    {
      label:
        "Use gifts and consumer groups relationships to drive traffic to your business",
    },
    {
      label:
        "Significantly underserved gift-card and e-gifting industry in Mexico and Latin America",
    },
    {
      label: "Social currency",
    },
    {
      label: "20 second gifting process",
    },
  ],
  button: {
    link: "#",
    label: "Learn More",
  },
};

export const BLOG_DATA = {
  blockText: {
    title: "What our author post on Newsfeed",
    text: "Build an incredible workplace and grow your business with Gusto",
  },
  posts: [
    {
      image: blog1,
      title: "SecD3v",
      link: {
        label: "Learn more",
        path: "#",
      },
    },
    {
      image: blog2,
      title: "Why the Best Websites Focus on Their first Conversion Funnel",
      link: {
        label: "Learn more",
        path: "#",
      },
    },
    {
      image: blog3,
      title:
        "Acquire More Leads Through Your Website By Switching Perspectives",
      link: {
        label: "Learn more",
        path: "#",
      },
    },
  ],
};

export const CALL_TO_ACTION_DATA = {
  title: "More than 20+ Integrations",
  text: "Pick one of our stock themes, or create your custom theme with the most advanced theme editor on any online survey building tool. It is built to perform and run fast on all of the latest mobile devices.",
  button: {
    link: "#",
    label: "View all Integrations",
  },
  bubbleIcon: [
    {
      icon: bubbleImage1,
    },
    {
      icon: bubbleImage2,
    },
    {
      icon: bubbleImage3,
    },
    {
      icon: bubbleImage4,
    },
    {
      icon: bubbleImage5,
    },
    {
      icon: bubbleImage6,
    },
    {
      icon: bubbleImage7,
    },
  ],
};

export const FOOTER_WIDGET = [
  {
    title: "Contact",
    menuItems: [
      {
        url: "#",
        text: "Melbourne ~ Naarm",
      },
      {
        url: "#",
        text: "Canberra ~ Ngambri",
      },
      {
        url: "#",
        text: "info@x-rd.com.au",
      },
      {
        url: "#",
        text: "(02) 6109 7672",
      },
    ],
  },
  // {
  //   title: "Solutions & Services",
  //   menuItems: [
  //     {
  //       url: "/solutions",
  //       text: "SecD3v",
  //     },
  //     {
  //       url: "/solutions",
  //       text: "Hab1tat",
  //     },
  //     {
  //       url: "/government",
  //       text: "Government",
  //     },
  //     {
  //       url: "/case-studies",
  //       text: "Case Studies",
  //     },
  //   ],
  // },
  {
    title: "Company",
    menuItems: [
      {
        url: "/about",
        text: "About Us",
      },
      // {
      //   url: "/partnerships",
      //   text: "Partnerships",
      // },
      // {
      //   url: "/philantropic-initiatives",
      //   text: "Philantropic Initiatives",
      // },
      // {
      //   url: "/blog",
      //   text: "Blog",
      // },
      {
        url: "/careers",
        text: "Careers",
      },
      // {
      //   url: "/security-compliance",
      //   text: "Security & Compliance",
      // },
    ],
  },
];

export const FOOTER_DATA = {
  logo: footerLogo,
  menu: [
    {
      link: "#",
      label: "Support",
    },
    {
      link: "#",
      label: "About Us",
    },
    {
      link: "#",
      label: "Privacy",
    },
    {
      link: "#",
      label: "Contact",
    },
  ],
  social: [
    {
      icon: footerFacebook,
      link: "#",
    },
    {
      icon: footerTwitter,
      link: "#",
    },
    {
      icon: footerDribble,
      link: "#",
    },
  ],
};
